<template>
<div class="recover-container">
    <TourialLogo />
    <div style="width: 100%;">
        <h3 class="bold">{{ $t('recover.title') }}</h3>
        <p>{{ $t('recover.caption') }}</p>
    </div>

    <form>
        <vs-input block :placeholder="$t('common.email')" type="email" disabled :value="$route.params.email" />
        <vs-input block :placeholder="$t('common.password')" type="password" v-model="password" />
        <vs-input block :placeholder="$t('common.confirmPassword')" type="password" v-model="rpassword" />
    </form>
    <p style="width: 100%; cursor: pointer;" @click="$router.push({name:'Accedi'})">
        {{ $t('forgot.back') }}
    </p>

    <vs-button size="large" block class="bold" @click="recoverEvent" :loading="loading">{{ $t('common.continue').toUpperCase() }}</vs-button>

    <p class="center">
        {{ $t('login.dontHaveAccount') }}<br />
        <router-link to="/explore/welcome">{{ $t('login.signUp') }}</router-link>
    </p>

    <p class="center" style="margin-top: 50px;">
        {{ $t('login.signInWith') }}
    </p>

    <vs-button transparent size="large"><img src="../../assets/icons/google.png" /></vs-button>
</div>
</template>

    
<script>
import TourialLogo from '../TourialLogo.vue';
import {
    apiCall,
    hash
} from '../../utils/ApiMiddleware';
export default {
    name: 'recover',
    props: {
        iEmail: String,
    },
    data() {
        return {
            password: '',
            rpassword: '',
            loading: false
        }
    },
    methods: {
        async recoverEvent() {
            this.loading = true;
            await this.recover();
            this.loading = false;
        },

        async recover() {
            // if password is too short show an error message
            if (this.password.length < 8) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('register.messages.passwordTooShort'),
                    color: 'danger',
                    position: 'top-right'
                });
                return;
            }

            // if the two password doesnt match show an error message
            if (this.password != this.rpassword) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('register.messages.passwordMismatch'),
                    color: 'danger',
                    position: 'top-right'
                });
                return;
            }

            // use apiCall to call the login api
            const response = await apiCall('POST', '/users/recover/confirm', {
                email: this.$route.params.email,
                password: hash(this.password),
                token: this.$route.params.token
            });
            // if status code is 200, show a success message and redirect to the explore page, otherwise show an error message
            if (response.status == 200) {
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('recover.messages.passwordReseted'),
                    color: 'success',
                    position: 'top-right'
                });
                this.$router.push({
                    name: 'Accedi'
                })
            } else if (response.status != 0) { // 0 is an already handled error, no needs to show another error message
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('recover.messages.cannotReset'),
                    color: 'danger',
                    position: 'top-right'
                });
            }
        }
    },
    mounted() {
        //this.email = this.iEmail;
    },
    components: {
        TourialLogo
    }
}
</script>

    
<style scoped>
.recover-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

/* DESKTOP */
@media (min-width: 1024px) {
    .recover-container {
        width: 350px;
    }
}

/* MOBILE */
@media (max-width: 600px) {
    .recover-container {
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 80px !important;
        width: 100% !important;

    }
}
</style>
